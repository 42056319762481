import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../components/layout"
import BannerSlide from "../components/Banner/BannerSlide"
import H3 from "../utils/H3"
import H6 from "../utils/H6"
import Button from "../utils/Button"
import { motion, AnimatePresence } from "framer-motion"
import Counter from "../components/Counter"
import TestimonialSlider from "../components/TestimonialSlider"
import ReactPlayer from 'react-player/youtube'
import TeacherTile from "../components/TeacherTile"
import WhySection from "../components/Courses/WhySection"
import CurriculumSection from "../components/Courses/CurriculumSection"
import PassionSection from "../components/Courses/PassionSection"
import MeetTeacherSection from "../components/Courses/MeetTeacherSection"
import PriceList from "../components/PriceList"
import PriceListSlider from "../components/slider/swiper"
import { plans } from "../data/hindustani-plans";
import Single from "../components/Banner/Single"
import GuruSection from "../components/Courses/GuruSection"
import CounterSection from "../components/CounterSection";
import OrangePerfomanceSections from "../components/Courses/OrangePerfomanceSections"
import FAQSection from "../components/Courses/FAQSection"
import Favicon from "../images/favicon.svg";
import Seo from "../components/seo";
const HindustaniPage = () => {
    const bharatnatyamPlans = plans.content;
    return (
        <Layout>
            <Seo title="Learn Hindustani Music Online" url="https://kaladhwani.com/learn-hindustani-music-online" description="Free Online Hindustani Music Trial Class. Kids and Adults. Live Video Classes. Book your demo now. All age groups." image={Favicon} />
            <Single
                image={"/images/hindustaniBanner.jpg"}
                mobileImage={"/images/hindustaniMobileBanner.jpg"}
                title="Hindustani Music"
                subtitle="learn"
            />
            <WhySection
                image="/images/whyHindustani.jpg"
                title="Why Learn Hindustani Music?"
                description="When we are born, the first music which we hear is our heartbeat. This in turn, defines how connected we are to music right from an early age. Hindustani Music is one of the <strong>musical traditions of India,</strong> originating from the Northern part of India. <strong>Mastery in this form allows a child to easily master other latest popular forms of music.</strong> The grammar and technique of Hindustani Music not only teaches the child to sing, but several other values such as discipline, consistency, focus and art appreciation. Hindustani Music in depth, has many genres such as <strong>Dhrupad, Khayal, Dadra, Kajri, Bhajan, Dhamar, Thumri, Chaiti, Tappa</strong> and many more, which cuts across language barriers for the student to engage with."
            />
            <CurriculumSection
                title="Our 4C Curriculum of Hindustani Music"
                music={true}
                video="https://www.youtube.com/embed/7kyaaau8ZKs"
                description="Just like we tune an instrument before playing; in music, we tune our voice. Unlike us, most music schools have little experience in designing a curriculum. This is because our curriculum is curated by artists who have been teaching across age groups. We understand each individual’s strengths and challenges, and carefully facilitate the students in getting adept at style specific training through <i>Conditioning and Command</i>. <i>Creativity and Composition</i> helps students understand the performative aspect."
            />
            <PassionSection
                keyword="Hindustani Music"
                link="/book-free-trial"
                image="md:bg-passionHindustani bg-hindustaniPassionMobile"
            />
            <MeetTeacherSection
                name="Ms. Tara Kannan"
                role="Hindustani Vocalist"
                image="/images/taraK.jpg"
                alt="Ms. Tara Kannan"
                description="Tara Kannan has been pursuing music since the age of 10. She is a disciple of <a href='https://ojeshpratapsingh.com/' target='_blank' class='underline hover:text-red'><strong>Prof. Ojesh Pratap Singh.</strong><a/> Initially trained under Smt. Ketaki Marathe and Shri Mukund Marathe, she further honed her skills under Pt. Pradeep Natekar (A stalwart exponent of Agra, Gwalior and Jaipur Gharanas, who belongs to the traditional musical lineage of Late ‘SangeetBhushan’ Pt. Ram Marathe and Pt. Gajanan Buwa Joshi).<br/><br/>A Gold medalist and A University topper, both in Masters and MPhil (University of Delhi), she is a recipient of ‘Sangeet Kala Vibhooti Samman’, and the prestigious ‘National Scholarship’ by the Ministry Of Culture, Govt. of India . Currently, she is pursuing her Doctorate in Hindustani Music from Faculty of Music, University of Delhi. "
                sectionInfo="We believe that performing and teaching music are two different skill sets, and hence we take special care to ensure that we hire highly qualified music facilitators,  who are trained and enjoy both the domains. Thus, our one of a kind 4C Curriculum is a result of meticulous planning and hardwork, which ensures that the child expands his/her horizon into the world of music. <strong>Like they say, well begun is half done. Similarly a good vocal teacher is important to bring the best out of the child.</strong>"
                link="https://www.youtube.com/channel/UCt0D0_IctIYDpTivpKrqO3g"
                pdf="/tara-kanan-bio.pdf"
                callout="Learn with Kannan"
                heading="Meet Your Teacher"
            />
            <div className="flex flex-col justify-center items-center md:bg-taraPerformance bg-leavesBg bg-cover bg-no-repeat h-auto w-full relative">
                <div className="flex flex-col justify-center items-center py-12 w-full relative bg-blackGradient">
                    <H3 color="text-white" uppercase>TARA’s performances</H3>
                    <div className="grid md:grid-cols-3 grid-cols-1 grid-rows-3 md:grid-rows-1 gap-12 w-full lg:px-28 md:px-12 px-4">
                        <div className="w-full rounded-4xl flex flex-col justify-start items-start">
                            <ReactPlayer controls={true} url='https://www.youtube.com/embed/gX2uLl6OZeE' className="react-player" width="100%" height="219px" />
                            <p className="text-white text-lg mt-4 font-bold">Raag Jaijaivanti | Tara Kannan</p>
                        </div>
                        <div className="w-full rounded-4xl flex flex-col justify-start items-start">
                            <ReactPlayer controls={true} url='https://www.youtube.com/embed/-0AD3SUW-Cg' className="react-player" width="100%" height="219px" />
                            <p className="text-white text-lg mt-4 font-bold">Raag Bihag | Tara Kannan | Kaladhwanil</p>
                        </div>
                        <div className="w-full rounded-4xl flex flex-col justify-start items-start">
                            <ReactPlayer controls={true} url='https://www.youtube.com/watch?v=-fuvDpmulAg' className="react-player" width="100%" height="219px" />
                            <p className="text-white text-lg mt-4 font-bold">Kaladhwani Collective | Swastivachan Mantras</p>
                        </div>
                    </div>
                    <a href="https://www.youtube.com/channel/UCt0D0_IctIYDpTivpKrqO3g" target="_blank" rel="noreferrer" className="text-white text-sm font-bold underline ml-auto mr-20 mt-8 transition-all duration-300 ease transform hover:scale-105">See More &gt;&gt;</a>
                </div>
            </div>

            <PriceList plans={bharatnatyamPlans} dance="bharatnatyam" curriculum="/HINDUSTANI MUSIC CURRICULUM.pdf"></PriceList>
            <PriceListSlider plans={bharatnatyamPlans} dance="bharatnatyam" curriculum="/HINDUSTANI MUSIC CURRICULUM.pdf"></PriceListSlider>
            {/* counters section */}
            <CounterSection></CounterSection>
            <div className="bg-white py-12 flex justify-center flex-col items-center">
                <H3 color="text-black" uppercase>Hear from our community</H3>
                <TestimonialSlider />
            </div>
            <OrangePerfomanceSections></OrangePerfomanceSections>
            <FAQSection></FAQSection>
        </Layout >
    )
}

export default HindustaniPage





