export const plans = {
    content: [
        {
            id: 1,
            title: "PRAYAAS",
            price:
            {
                personal:
                {
                    monthly: "3,500",
                    semiAnnualy: "18,900",
                    perClassMonthly: "437",
                    perClassSemi: "393",
                },
                group: {
                    monthly: "2,500",
                    semiAnnualy: "13,500",
                    perClassMonthly: "312",
                    perClassSemi: "281",
                },
            },
            level: "BEGINNER LEVEL",
            description: "This course is for students who want to initiate their training into Vocal Music – Hindustani Tradition. The training of the voice is very crucial as that forms the foundation of learning the art form. The student learns, identifies, explores and demonstrates the basic knowledge of Hindustani Vocal Music.",
            pointers: [
                {
                    id: 1,
                    point: "No prior experience",
                },
                {
                    id: 2,
                    point: "Age : 6 onwards",
                },
                {
                    id: 3,
                    point: "8 classes / month",
                },
                {
                    id: 4,
                    point: "2 year course",
                },
                {
                    id: 5,
                    point: "Live and Online Classes",
                },
                {
                    id: 6,
                    point: "Participation in cultural events",
                },
            ],
            achievements: [
                {
                    id: 1,
                    point: "Kaladhwani Level I Certification",
                },
                {
                    id: 2,
                    point: "Introduction to Hindustani Vocal Music",
                },
            ],
            stars: 1,
        },
        {
            id: 2,
            title: "ABHYAAS",
            price:
            {
                personal:
                {
                    monthly: "4,000",
                    semiAnnualy: "21,600",
                    perClassMonthly: "500",
                    perClassSemi: "450",
                },
                group: {
                    monthly: "3,000",
                    semiAnnualy: "16,200",
                    perClassMonthly: "375",
                    perClassSemi: "337",
                },
            },
            level: "INTERMEDIATE LEVEL",
            description: "This course is a level up: where students will get initiated into learning the nuances and characteristic details of different Raagas and Taalas. The Curriculum provides a holistic learning to the child wherein theoretical aspects and allied arts are weaved into the curriculum.",
            pointers: [
                {
                    id: 1,
                    point: "Min. 2 years or possess knowledge equivalent to the student at the Prayaas Batch",
                },
                {
                    id: 2,
                    point: "Age : Students will be selected based on their demo sessions",
                },
                {
                    id: 3,
                    point: "8 classes / month",
                },
                {
                    id: 4,
                    point: "3 year course",
                },
                {
                    id: 5,
                    point: "Live and Online Classes",
                },
                {
                    id: 6,
                    point: "Participation in Competitions and Cultural festivals",
                },
            ],
            achievements: [
                {
                    id: 1,
                    point: "Kaladhwani Level II Certification",
                },
                {
                    id: 2,
                    point: "Introduction to Compositions",
                },
                {
                    id: 3,
                    point: "Performance Showcase with live musicians - Kalarati",
                },
            ],
            stars: 2,
        },
        {
            id: 3,
            title: "ULLAAS",
            price:
            {
                personal:
                {
                    monthly: "4,000 ",
                    semiAnnualy: "21,600",
                    perClassMonthly: "500",
                    perClassSemi: "450",
                },
                group: {
                    monthly: "3,500",
                    semiAnnualy: "18,900",
                    perClassMonthly: "437",
                    perClassSemi: "393",
                },
            },
            level: "ADVANCED LEVEL",
            description: "This batch is for students who would like to dive deep into the cultural ethos and history of the music. Students are trained for both Solo, and Group presentations. The student learns, identifies, explores and demonstrates the performative aspect.",
            pointers: [
                {
                    id: 1,
                    point: "Min. 5 years or possess knowledge equivalent to the student at the Abhyaas Batch",
                },
                {
                    id: 2,
                    point: "Age : Students will be selected based on their demo sessions",
                },
                {
                    id: 3,
                    point: "8 classes / month",
                },
                {
                    id: 4,
                    point: "3 year course",
                },
                {
                    id: 5,
                    point: "Live and Online Classes",
                },
                {
                    id: 6,
                    point: "Participation in Competitions and Cultural festivals",
                },
            ],
            achievements: [
                {
                    id: 1,
                    point: "Kaladhwani Level III Certification",
                },
                {
                    id: 2,
                    point: "A Complete Margam",
                },
                {
                    id: 3,
                    point: "Performance Showcase with live musicians - Kalarati and Kalakriti",
                },
            ],
            stars: 3,
        },
    ],
};



